<template>
  <header>
    <div class="container">
      <router-link to="/home">
        <img
          class="logo"
          src="../../assets/images/Tradecorp-logo.png"
          alt="Tradecorp logo"
        />
      </router-link>

      <nav v-show="showMenu">
        <ul>
          <li>
            <p @click="scrollTo('vorax')">VORAX</p>
          </li>
          <li>
            <p @click="scrollTo('obtencao')">OBTENÇÃO</p>
          </li>
          <li>
            <p @click="scrollTo('pesquisa')">PESQUISA</p>
          </li>
          <li>
            <p @click="scrollTo('resultados')">RESULTADOS</p>
          </li>
          <li>
            <p @click="scrollTo('videos')">VÍDEOS</p>
          </li>
          <li>
            <p class="red-border" @click="scrollTo('atendimento')">
              ATENDIMENTO
            </p>
          </li>
        </ul>
      </nav>

      <font-awesome-icon
        class="search-icon desktop-hidden tablet-hidden"
        :icon="['fas', 'bars']"
        @click="showMenu = !showMenu"
      />
    </div>
  </header>
</template>
<script>
export default {
  name: "VoraxHeader",
  data() {
    return {
      showMenu: window.innerWidth > 720,
    };
  },
  methods: {
    scrollTo(id) {
      let distance = document.querySelector(`#${id}`)?.offsetTop - 66;
      if (!distance) return;
      window.scroll({
        top: distance,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  width: 100vw;
  height: 66px;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;

  .container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img.logo {
      max-width: 200px;
    }
  }

  nav {
    margin-left: auto;
    > ul {
      position: relative;
      min-width: 410px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 25px;

      li {
        cursor: pointer;
        span {
          position: relative;
          margin-right: 8px;
        }
        svg {
          position: relative;
          top: -1px;
        }
      }

      a,
      p,
      span,
      svg {
        position: relative;
        transition: color 0.25s ease;
        cursor: pointer;
        &:hover {
          color: var(--primary);
        }
        &.red-border {
          border: 1px solid #e11010;
          padding: 8px 16px;
          &:hover {
            color: #e11010;
          }
        }
      }

      div:hover > svg,
      div:hover > span {
        color: var(--primary);
      }

      .submenu {
        position: absolute;
        top: 16px;
        background-color: #fff;
        padding: 28px 12px 0 12px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
        li a {
          position: relative;
          display: block;
          height: 40px;
        }
      }
    }
  }

  .search-icon {
    position: relative;
    margin-left: 22px;
    font-size: 1.2em;
    cursor: pointer;
  }
}

.router-link-active {
  color: var(--primary);
}

@media only screen and (max-width: 720px) {
  header {
    .container {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img.logo {
        max-width: 170px;
      }
    }

    nav {
      margin-left: auto;
      > ul {
        position: fixed;
        left: 0;
        top: 0;
        margin: 66px auto 0 auto;
        display: block;
        a,
        li {
          position: relative;
          display: block;
          height: 45px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-color: #fff;
          padding-left: 5%;
          margin: 0;
        }
        li {
          cursor: pointer;
          span {
            position: relative;
            margin-right: 8px;
          }
          svg {
            position: relative;
            top: -3px;
          }
        }

        a,
        span,
        svg {
          position: relative;
          transition: color 0.25s ease;
          &:hover {
            color: var(--primary);
          }
        }

        div:hover > svg,
        div:hover > span {
          color: var(--primary);
        }

        .submenu {
          position: relative;
          top: 0;
          background-color: #fff;
          padding: 28px 12px 0 12px;
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
          li a {
            position: relative;
            display: block;
            height: 40px;
          }
        }
      }
    }

    .search-icon {
      position: relative;
      margin-left: 22px;
      font-size: 1.2em;
      cursor: pointer;
    }
  }
}
</style>
