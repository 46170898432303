<template>
  <div>
    <Header />
    <section class="intro" style="padding: 0 !important">
      <div id="bannerSlider" class="swiper-container">
        <div class="swiper-wrapper">
          <div class="banner-intro-item swiper-slide">
            <div class="big">
              <img
                class="banner-img"
                src="../assets/images/vorax/banner1.png"
                alt="Vorax biofertilizante"
              />
            </div>
            <div class="small1 bg-red">
              <p class="vorax-title text-white">BIOFERTILIZANTE</p>
              <p class="vorax-title big text-white">Nº 1</p>
              <p class="vorax-title text-white">REGISTRADO NO BRASIL</p>
            </div>
            <div class="small2 bg-white">
              <div @click="scrollToContact" class="vorax-btn">FALE CONOSCO</div>
            </div>
          </div>

          <div class="banner-intro-item swiper-slide">
            <div class="big">
              <img
                class="banner-img"
                src="../assets/images/vorax/banner2.jpg"
                alt="Vorax biofertilizante"
              />
            </div>
            <div class="small1 bg-yellow">
              <p class="vorax-title text-white btn-banner">
                SABE POR QUE VORAX® É O BIOFERTILIZANTE
              </p>
              <p class="vorax-title big text-white btn-banner">MULTIAÇÃO</p>
              <p class="vorax-title text-white btn-banner">MAIS EFICIENTE?</p>
            </div>
            <div class="small2 bg-white">
              <a
                href="https://wa.me/5519982280765"
                target="_blank"
                class="vorax-btn red"
              >
                <span class="btn-span">WHATSAPP</span>
                <font-awesome-icon :icon="['fab', 'whatsapp']" />
              </a>
            </div>
          </div>
        </div>
        <div class="swiper-pagination vorax"></div>
      </div>
    </section>

    <section id="vorax" class="parallax">
      <div class="container">
        <h1 class="vorax-title big text-white">Vorax® Biofertilizante</h1>
        <p class="vorax-title text-white desktop6 mb-2">
          O Único Registrado, é Seguro e com Resultado Garantido!
        </p>
        <img
          class="prod"
          src="../assets/images/vorax/vorax.png"
          alt="Embalagem Vorax"
        />
      </div>
    </section>

    <section class="vorax-desc">
      <div class="container">
        <p class="vorax-title text-red desktop6 tablet6 mt-2-mobile">
          Vorax® é um biofertilizante com ação bioestimulante produzido a partir
          de um criterioso processo de fabricação envolvendo fermentação
          biológica.
        </p>
        <p class="text-desc mt-2 desktop6 tablet12">
          Através da microbiologia, obtemos um extrato rico em metabólitos que
          ativam vários mecanismos nos vegetais, com resultado de ganho em
          produtividade. Um dos principais ingredientes ativos é o aminoácido
          Ácido L-Glutâmico, utilizado em vários metabolismos das plantas. Ele
          atua em diferentes processos, conforme a necessidade de cada fase da
          cultura. Vorax® Biofertilizante inaugurou no Brasil essa classe de
          produtos. Após muitos anos de pesquisa, o produtor conta agora com um
          produto testado, aprovado e controlado pelo Ministério da Agricultura,
          com base em sua real composição e efeitos nas culturas.
        </p>
      </div>
    </section>

    <section id="obtencao" class="obtencao">
      <div class="container">
        <div class="vorax-logo">
          <span>OBTENÇÃO</span>
          <img src="../assets/images/vorax/logo-vorax.svg" alt="logo vorax" />
        </div>

        <div class="flex-block">
          <div class="block">
            <p class="vorax-title text-red">
              Com o Biofertilizante Vorax® não tem tempo ruim!
            </p>
            <p class="text-desc mt-2">
              Vorax Biofertilizante é obtido através da fermentação biológica do
              melaço de cana por uma “Bactéria do Bem”. Durante o crescimento da
              bactéria, o microrganismo libera metabólitos para o meio. Esse
              extrato fermentado resultante é concentrado e formulado.
              <br /><br />
              O rígido controle de qualidade envolve a realização de mais de 300
              análises durante a produção. Nossos estudos identificaram centenas
              de biomoléculas com comprovada atividade no metabolismo das
              plantas.
            </p>
          </div>
          <div class="block mt-2-mobile">
            <div
              id="tecSlider"
              class="swiper-container"
              style="overflow: hidden"
            >
              <div class="tec-img swiper-wrapper">
                <img
                  src="../assets/images/vorax/tec1.png"
                  alt="biofertilizante"
                  class="swiper-slide"
                />
                <img
                  src="../assets/images/vorax/tec2.png"
                  alt="biofertilizante"
                  class="swiper-slide"
                />
                <img
                  src="../assets/images/vorax/tec3.png"
                  alt="biofertilizante"
                  class="swiper-slide"
                />
                <img
                  src="../assets/images/vorax/tec4.png"
                  alt="biofertilizante"
                  class="swiper-slide"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="typer" style="padding: 0 !important">
      <div class="typer-wrapper">
        <div class="vorax-typer">{{ typedWord }} <span></span></div>
      </div>
      <img
        src="../assets/images/vorax/mask.png"
        alt="Vorax mascara"
        class="mask-img"
      />
    </section>

    <section id="pesquisa">
      <div class="container">
        <div class="vorax-logo">
          <span>PESQUISA</span>
          <img src="../assets/images/vorax/logo-vorax.svg" alt="logo vorax" />
        </div>
        <p class="text-desc text-center mt-2 desktop6 mx-auto">
          Foi desenvolvido em colaboração constante com a pesquisa científica,
          teve seu posicionamento definido com base em mais de 30 trabalhos
          agronômicos, gerando ganhos e surpreendendo produtores em todo o
          Brasil.
        </p>

        <div class="flex-block">
          <div class="block">
            <div
              id="researchSlider"
              class="swiper-container"
              style="overflow: hidden"
            >
              <div class="tec-img swiper-wrapper">
                <img
                  src="../assets/images/vorax/pesquisa1.png"
                  alt="grafico 1"
                  class="swiper-slide"
                />
                <img
                  src="../assets/images/vorax/pesquisa2.png"
                  alt="grafico 2"
                  class="swiper-slide"
                />
                <img
                  src="../assets/images/vorax/pesquisa3.png"
                  alt="grafico 3"
                  class="swiper-slide"
                />
                <img
                  src="../assets/images/vorax/pesquisa4.png"
                  alt="grafico 4"
                  class="swiper-slide"
                />
                <img
                  src="../assets/images/vorax/pesquisa5.png"
                  alt="grafico 5"
                  class="swiper-slide"
                />
              </div>
              <div
                id="researchPagination"
                class="swiper-pagination"
                style="bottom: 0px"
              ></div>
            </div>
          </div>
          <div class="block">
            <ul class="research mt-2-mobile">
              <li>Reduz a degradação das células pelos estresses</li>
              <li>Melhora a assimilação do nitrogênio</li>
              <li>Maior produção de clorofila e mais fotossíntese</li>
              <li>Estimula o metabolismo secundário</li>
              <li>Multiação no metabolismo vegetal</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="harvest">
      <div class="container">
        <p class="vorax-title text-white">
          MAIOR PRODUTIVIDADE E QUALIDADE PARA A SUA COLHEITA.
        </p>
        <img
          class="mobile-hidden"
          src="../assets/images/vorax/colheita.jpg"
          alt="colheita"
        />
      </div>
    </section>

    <section id="resultados">
      <div class="container">
        <div class="vorax-logo">
          <span>RESULTADOS</span>
          <img src="../assets/images/vorax/logo-vorax.svg" alt="logo vorax" />
        </div>
        <p class="text-desc text-center desktop4 mx-auto mt-2">
          Os resultados comprovam a eficiência na Soja - Incremento de
          produtividade (em sc/ha) com utilização do Biofertilizante Vorax®.
        </p>

        <img
          src="../assets/images/vorax/grafico_4.png"
          alt="grafico Vorax"
          class="result-chart"
        />

        <div class="flex-between mt-2 result">
          <div class="result-item">
            <img src="../assets/images/vorax/img-soja1.png" alt="soja" />
            <div class="content">
              <p>Mais ramificações e enraizamento na soja</p>
            </div>
          </div>
          <div class="result-item">
            <img src="../assets/images/vorax/img-soja2.png" alt="soja" />
            <div class="content">
              <p>Maior produtividade na soja</p>
            </div>
          </div>
        </div>

        <div id="resultSlider" class="swiper-container mt-2">
          <div class="swiper-wrapper result-wrapper">
            <div class="swiper-slide result-slide">
              <img src="../assets/images/vorax/img-uva.png" alt="uva" />
              <div class="content">
                <p>Brotação em uva</p>
              </div>
            </div>
            <div class="swiper-slide result-slide">
              <img src="../assets/images/vorax/img-cana.png" alt="cana" />
              <div class="content">
                <p>Brotação de cana-de-açúcar</p>
              </div>
            </div>
            <div class="swiper-slide result-slide">
              <img src="../assets/images/vorax/img-repolho.png" alt="repolho" />
              <div class="content">
                <p>Formação de raízes em repolho</p>
              </div>
            </div>
            <div class="swiper-slide result-slide">
              <img src="../assets/images/vorax/img-oregano.png" alt="orégano" />
              <div class="content">
                <p>Formação de raízes em orégano</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="typer" style="padding: 0 !important">
      <div class="typer-wrapper">
        <div class="vorax-typer">{{ typedWord }} <span></span></div>
      </div>
      <img
        src="../assets/images/vorax/mask.png"
        alt="Vorax mascara"
        class="mask-img"
      />
    </section>

    <section id="videos" class="videos">
      <div class="container">
        <h1 class="vorax-title text-red text-center" style="font-size: 2.4em">
          Os resultados comprovam a eficiência de Vorax®
        </h1>

        <div id="videosSlider" class="videos-container mt-2 swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/6d3sSm7rZoE"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Vigor das Guias e Qualidade de Frutos em Café Arábica - Fazenda
                Recanto
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/mqTpWXcBKdM"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Palestra Vorax Biofertilizante - Um Biofertilizante Multiação!
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/aXItymnjM5c"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Brasil conquista registro de biofertilizantes - Entrevista
                Roberto Batista
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/ib8RwXoMkCY"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Primeiro BioFertilizante Registrado do Brasil - Vorax
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/8ogc4Di1XmE"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Momento Fértil - Araguen Vanzetto - Campinas do Sul/RS
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/0siRwDxzRdU"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Bioestimulante ou Biofertilizante Vorax, você conhece o produto?
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/jNIfSTh4R2U"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Roberto Batista fala ao Dia Dia Rural do Canal TerraViva -
                Biofertilizante Vorax
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/T2hx9RYaJhM"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Matéria sobre o registro do primeiro biofertilizante do Brasil -
                TV Câmara
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/3oDgMaHiSz8"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Roberto Berwanger Batista explica o processo de obtenção e
                diferenciais do Vorax
              </p>
            </div>
            <div class="swiper-slide video-item">
              <iframe
                src="https://www.youtube.com/embed/Lv1rW41MPUI"
                frameborder="0"
              ></iframe>
              <p class="text-desc text-center">
                Momento Fértil com o Dr. Átila Francisco Mógor
              </p>
            </div>
          </div>
          <div class="videos-controllers-holder">
            <div id="videosSliderPrev" class="banner-prev">
              <font-awesome-icon icon="chevron-left" />
            </div>
            <div id="videosSliderNext" class="banner-next">
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="atendimento" class="contact">
      <img
        src="../assets/images/vorax/vdourado.png"
        alt="v dourado"
        class="bg-img mobile-hidden"
      />
      <div class="container">
        <p class="vorax-title text-white text-center mx-3-desktop">
          ENTRE EM CONTATO CONOSCO E ENCONTRE UM REPRESENTANTE PERTO DE VOCÊ!
        </p>

        <div class="form-holder mt-2">
          <div class="form-item">
            <span>NOME *</span>
            <input v-model="form.name" type="text" />
          </div>
          <div class="form-item">
            <span>TELEFONE *</span>
            <input v-model="form.phoneNumber" type="text" />
          </div>
          <div class="form-item">
            <span>E-MAIL *</span>
            <input v-model="form.email" type="text" />
          </div>
          <div class="form-item">
            <span>CIDADE *</span>
            <input v-model="form.city" type="text" />
          </div>
          <div class="form-item">
            <span>INTERESSE *</span>
            <select v-model="form.interest">
              <option value="Informações sobre o produto">
                Informações sobre o produto
              </option>
              <option value="Quero comprar">Quero comprar</option>
              <option value="Envio de currículo">Envio de currículo</option>
              <option value="Fornecedor de matéria-prima">
                Fornecedor de matéria-prima
              </option>
            </select>
          </div>
          <div class="form-item">
            <span>ESTADO *</span>
            <select v-model="form.state">
              <option value="Acre">Acre</option>
              <option value="Alagoas">Alagoas</option>
              <option value="Amapá">Amapá</option>
              <option value="Amazonas">Amazonas</option>
              <option value="Bahia">Bahia</option>
              <option value="Ceará">Ceará</option>
              <option value="Distrito Federal">Distrito Federal</option>
              <option value="Espírito Santo">Espírito Santo</option>
              <option value="Goiás">Goiás</option>
              <option value="Maranhão">Maranhão</option>
              <option value="Mato Grosso">Mato Grosso</option>
              <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
              <option value="Minas Gerais">Minas Gerais</option>
              <option value="Paraná">Paraná</option>
              <option value="Paraíba">Paraíba</option>
              <option value="Pará">Pará</option>
              <option value="Pernambuco">Pernambuco</option>
              <option value="Piauí">Piauí</option>
              <option value="Rio de Janeiro">Rio de Janeiro</option>
              <option value="Rio Grande do Norte">Rio Grande do Norte</option>
              <option value="Rio Grande do Sul">Rio Grande do Sul</option>
              <option value="Rondonia">Rondonia</option>
              <option value="Roraima">Roraima</option>
              <option value="Santa Catarina">Santa Catarina</option>
              <option value="Sergipe">Sergipe</option>
              <option value="São Paulo">São Paulo</option>
              <option value="Tocantins">Tocantins</option>
            </select>
          </div>

          <div class="form-item textarea">
            <span>MENSAGEM *</span>
            <textarea v-model="form.message"></textarea>
          </div>

          <vue-recaptcha
            class="mt-1"
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            sitekey="6LcY6j8aAAAAAH3V89ex5-QQka24KD9ihjnZswW2"
            :loadRecaptchaScript="true"
          />

          <transition enter-active-class="animated fadeIn">
            <p
              v-show="warningMessage != ''"
              class="warning desktop12 tablet12 mobile12"
            >
              {{ warningMessage }}
            </p>
          </transition>

          <button
            @click="sendForm"
            class="btn border icon yellow desktop4 mx-auto"
            v-show="validHuman"
          >
            <span>ENVIAR</span>
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </button>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>
<script
  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
  async
  defer
></script>
<script>
import http from "@/http";
import Header from "@/components/Vorax/Header";
import Footer from "@/components/Vorax/Footer";
import validators from "../util/validators";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay]);
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Vorax",
  components: {
    Header,
    Footer,
    "vue-recaptcha": VueRecaptcha,
  },
  data() {
    return {
      form: {
        name: "",
        phoneNumber: "",
        email: "",
        city: "",
        state: "",
        interest: "",
        message: "",
        subject: "Vorax",
      },
      typedWord: "",
      words: ["AGRICULTURA", "INOVAÇÃO", "CRESCIMENTO"],
      wordIndex: 0,
      currentWordIndex: 0,
      currentWord: "",
      warningMessage: "",
      sendingForm: false,
      validHuman: false,
    };
  },
  created() {
    this.startBannerSlider();
    this.startTecSlider();
    this.startResearchSlider();
    this.startVideosSlider();

    // check window size ...
    //this.startResultSlider();

    this.currentWord = this.words[this.currentWordIndex];
    this.typeWords();
  },
  methods: {
    scrollToContact() {
      let distance = document.querySelector("#atendimento").offsetTop - 60;
      window.scroll({
        top: distance,
        behavior: "smooth",
      });
    },
    onVerify: function (response) {
      console.log("Verify: " + response);
      this.validHuman = true;
    },
    onExpired: function () {
      console.log("Expired");
      this.validHuman = false;
    },
    sendForm() {
      if (this.form.name.length < 3) {
        this.warningMessage = "Por favor, informe o seu nome";
        return;
      }
      if (this.form.phoneNumber.length < 8) {
        this.warningMessage = "Por favor, informe um telefone";
        return;
      }
      if (!validators.validateEmail(this.form.email)) {
        this.warningMessage = "Por favor, informe um e-mail válido";
        return;
      }
      if (this.form.city.length < 2) {
        this.warningMessage = "Por favor, informe a sua cidade";
        return;
      }
      if (!this.form.interest) {
        this.warningMessage = "Por favor, informe um interesse";
        return;
      }
      if (!this.form.state) {
        this.warningMessage = "Por favor, informe o seu estado";
        return;
      }
      if (this.form.message.length < 3) {
        this.warningMessage = "Por favor, digite a sua mensagem";
        return;
      }

      this.warningMessage = "";
      this.postForm();
    },
    clearForm() {
      this.form.name = "";
      this.form.phoneNumber = "";
      this.form.email = "";
      this.form.state = "";
      this.form.city = "";
      this.form.interest = "";
      this.form.message = "";
    },
    postForm() {
      this.sendingForm = true;
      http
        .post("Contact", this.form)
        .then((res) => {
          this.warningMessage = "Contato enviado com sucesso!";
          this.clearForm();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.sendingForm = false;
        });
    },
    typeWords() {
      let startTimer = () => {
        let timer = setInterval(() => {
          if (this.wordIndex < this.currentWord.length) {
            this.typedWord += this.currentWord[this.wordIndex];
            this.wordIndex++;
          } else {
            clearInterval(timer);
            setTimeout(() => {
              if (this.currentWordIndex < this.words.length - 1) {
                this.currentWordIndex++;
              } else {
                this.currentWordIndex = 0;
              }
              this.currentWord = this.words[this.currentWordIndex];
              this.wordIndex = 0;
              this.typedWord = "";
              startTimer();
            }, 2000);
          }
        }, 95);
      };
      startTimer();
    },
    startVideosSlider() {
      setTimeout(() => {
        new Swiper("#videosSlider", {
          speed: 450,
          slidesPerView: 2,
          spaceBetween: 35,
          loop: true,
          preventInteractionOnTransition: true,
          navigation: {
            nextEl: "#videosSliderNext",
            prevEl: "#videosSliderPrev",
          },
          breakpoints: {
            1030: {
              slidesPerView: 2,
              spaceBetween: 35,
            },
            200: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          },
        });
      }, 200);
    },
    startResultSlider() {
      setTimeout(() => {
        new Swiper("#resultSlider", {
          autoplay: { delay: 4000, disableOnInteraction: false },
          speed: 450,
          slidesPerView: 3,
          loop: true,
          preventInteractionOnTransition: true,
        });
      }, 200);
    },
    startResearchSlider() {
      setTimeout(() => {
        new Swiper("#researchSlider", {
          autoplay: { delay: 4000, disableOnInteraction: false },
          speed: 450,
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          preventInteractionOnTransition: true,
          grabCursor: true,
          pagination: {
            el: "#researchPagination",
            clickable: true,
          },
        });
      }, 200);
    },
    startTecSlider() {
      setTimeout(() => {
        new Swiper("#tecSlider", {
          autoplay: { delay: 5000, disableOnInteraction: false },
          speed: 450,
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          preventInteractionOnTransition: true,
          grabCursor: true,
        });
      }, 200);
    },
    startBannerSlider() {
      setTimeout(() => {
        new Swiper("#bannerSlider", {
          autoplay: { delay: 5000, disableOnInteraction: false },
          speed: 450,
          autoHeight: true,
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          pagination: {
            el: ".swiper-pagination",
          },
        });
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
section.intro {
  margin-top: 66px;
  padding: 0;
}
.banner-intro-item {
  display: grid;
  gap: 0;
  grid-template-areas:
    "big small1"
    "big small2";
  grid-template-columns: 0.66fr 0.34fr;
  .banner-img {
    width: 100%;
  }
  .big {
    grid-area: big;
  }
  .small1 {
    grid-area: small1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 25px;
  }
  .small2 {
    grid-area: small2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2.5rem;
  }
}

.vorax-btn {
  position: relative;
  padding: 14px 22px;
  width: 100%;
  background-color: #f7e022;
  background-image: linear-gradient(180deg, #f7e022, #f79022);
  border: 2px solid #f7c222;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: fontBold;
  font-size: 2em;
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  &.red {
    background-color: #fb523e;
    background-image: linear-gradient(180deg, #fb523e, #b71907);
    border: 2px solid #fb523e;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 20px;
    span,
    svg {
      font-family: fontBold;
      font-weight: bolder;
      font-size: 1.1em;
      color: #fff;
    }
  }
}

.vorax-title {
  font-family: fontBold;
  font-weight: bolder;
  font-size: 1.6em;
  &.big {
    font-size: 3.4em;
  }
}

.text-red {
  color: #d61802 !important;
}
.text-white {
  color: #fff;
}
.bg-red {
  background-color: #d61802;
}
.bg-yellow {
  background-color: #f7c222;
}

section.parallax {
  position: relative;
  width: 100vw;
  height: 448px;
  padding: 0;
  margin-top: -4px;
  background-image: url("../assets/images/vorax/parallax.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 2;
  .container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    .vorax-title {
      font-family: fontLight;
      font-size: 2.6em;
      &.big {
        font-family: fontBold;
        font-size: 3.8em;
      }
    }
  }
  img.prod {
    position: absolute;
    max-width: 430px;
    right: 2rem;
    bottom: -320px;
  }
}

section.vorax-desc {
  background-color: #f7f7f7;
  z-index: 1;
}

.vorax-logo {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-width: 240px;
  }
  span {
    font-size: 20px;
    letter-spacing: 2px;
  }
}

.flex-block {
  position: relative;
  width: 100%;
  margin: 35px auto 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 0 55px;
  .block {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .tec-img {
      position: relative;
      width: 100%;
      max-width: 450px;
    }
  }
}

section.typer {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  justify-content: flex-start;
  align-items: center;
  background-image: url("../assets/images/vorax/fundodourado.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  padding: 0;
  &::before {
    position: absolute;
    content: "";
    width: 55%;
    height: 100%;
    background-color: #d61802;
    left: 0;
    top: 0;
  }
  .mask-img {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  .typer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .vorax-typer {
      position: relative;
      font-family: fontBold;
      font-size: 4.4em;
      color: #fff;
      text-align: center;
      span {
        position: absolute;
        width: 3px;
        height: 60%;
        top: 14px;
        right: -10px;
        background-color: #fff;
        z-index: 2;
        animation: blink 0.6s ease infinite alternate;
      }
    }
  }
}

ul.research {
  position: relative;
  li {
    position: relative;
    height: 42px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    border-left: 2px solid #d61802;
    margin-top: 15px;
    font-size: 1.2em;
  }
}

section.harvest {
  height: 170px;
  padding: 0;
  margin: 50px auto 100px auto;
  background-image: linear-gradient(
    to right,
    rgba(156, 15, 1, 1) 0%,
    rgba(221, 9, 2, 1) 50%,
    rgba(156, 15, 1, 1) 100%
  );
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    img {
      position: relative;
      display: block;
      width: 100%;
      max-width: 470px;
    }
  }
}

img.result-chart {
  position: relative;
  width: 100%;
  margin-top: 35px;
}

.result-item {
  position: relative;
  width: 49%;
  max-width: 560px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    position: relative;
    display: block;
    width: 50%;
    z-index: 2;
  }
  .content {
    position: relative;
    padding: 14px 22px 14px 36px;
    left: -47px;
    background-color: #f7c222;
    z-index: 1;
    p {
      font-family: fontBold;
      font-size: 1.2em;
      color: #333;
    }
  }
}

.result-wrapper {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  justify-content: space-between;
  gap: 0 25px;
}

.result-slide {
  position: relative;
  max-width: 280px;
  img {
    position: relative;
    width: 100%;
    z-index: 2;
  }
  .content {
    position: relative;
    width: 70%;
    padding: 14px 22px;
    background-color: #f7c222;
    margin: -20px auto 0 auto;
    left: -18px;
    z-index: 1;
    p {
      font-family: fontBold;
      font-size: 1.2em;
      color: #333;
    }
  }
}

.video-item {
  position: relative;
  padding-bottom: calc(56.25% / 2 - 10px);
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  p {
    position: absolute;
    margin-top: 60%;
    font-size: 1.1em !important;
    width: 100%;
  }
}

.videos-controllers-holder {
  position: relative;
  margin: 45px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 35px;
  .banner-prev,
  .banner-next {
    padding: 15px;
    cursor: pointer;
  }
  svg {
    font-size: 1.2em;
  }
}

section.contact {
  background-image: linear-gradient(
    to right,
    rgba(156, 15, 1, 1) 0%,
    rgba(221, 9, 2, 1) 50%,
    rgba(156, 15, 1, 1) 100%
  );
  .container {
    max-width: 720px;
  }
  .bg-img {
    position: absolute;
    height: 100%;
    right: 7rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.7;
  }
}

.form-holder {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .form-item {
    position: relative;
    width: 31%;
    margin-top: 15px;
    &.textarea {
      width: 100%;
    }
    span {
      position: relative;
      display: block;
      font-size: 1em;
      color: #fff;
      margin-bottom: 5px;
    }
    input,
    select {
      position: relative;
      width: 100%;
      height: 40px;
      border: none;
      padding-left: 10px;
      background-color: #fff;
    }
    textarea {
      position: relative;
      width: 100%;
      height: 90px;
      resize: none;
      padding-left: 10px;
      padding-top: 8px;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 720px) {
  .banner-intro-item {
    display: block;
    .banner-img {
      width: 100%;
    }
    .big {
      margin-bottom: -3px;
    }
    .small1 {
      padding: 25px;
    }
    .small2 {
      padding: 25px;
    }
  }

  section.parallax {
    height: 510px;
    .container {
      padding-top: 0 !important;
    }
    img.prod {
      width: 80%;
      bottom: -230px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
    .container {
      justify-content: flex-start;
      padding-top: 35px;
    }
  }

  #tecSlider {
    position: relative;
    width: 100%;
  }

  .flex-block {
    display: block;
    .block {
      width: 100%;
      .tec-img {
        max-width: initial;
      }
    }
  }

  section.typer {
    display: block;
    height: 360px;
    &::before {
      width: 100%;
      height: 50%;
      background-color: #d61802;
      left: 0;
      top: 0;
    }
    .typer-wrapper {
      padding-top: 45px;
      .vorax-typer {
        position: relative;
        font-family: fontBold;
        font-size: 2.6em;
        span {
          position: absolute;
          width: 3px;
          height: 60%;
          top: 8px;
          right: -10px;
          background-color: #fff;
          z-index: 2;
          animation: blink 0.6s ease infinite alternate;
        }
      }
    }
    .mask-img {
      position: absolute;
      width: 100%;
      margin: 0 auto;
      bottom: 0;
    }
  }

  #researchSlider {
    position: relative;
    width: 100%;
  }

  .vorax-title {
    font-size: 1.8em;
  }

  section.harvest {
    margin: 0;
    padding: 45px 0;
    height: initial;
  }

  .result-item {
    width: 100%;
    display: block;
    margin-top: 45px;
    img {
      width: 100%;
    }
    .content {
      padding: 24px 22px 14px 36px;
      width: 70%;
      margin: -23px auto 0 auto;
      left: -24px;
    }
  }

  .result-wrapper {
    gap: 45px 0;
    margin-top: 25px;
    .result-slide {
      margin: 0 auto;
      max-width: initial;
    }
  }

  #videosSlider {
    position: relative;
    width: 100%;
  }

  .video-item {
    padding-bottom: 56.25%;
    p {
      position: absolute;
      margin-top: 60%;
      font-size: 1.1em !important;
    }
  }

  .form-holder .form-item {
    width: 48%;
  }
}

@media only screen and (min-width: 721px) and (max-width: 1160px) {
  .banner-intro-item {
    grid-template-columns: 66% 34%;
    .vorax-title.btn-banner {
      font-size: 1.5em;
      &.big {
        font-size: 3em;
      }
    }
    .vorax-btn span.btn-span {
      font-size: 0.9em;
    }
  }
  section.parallax .container {
    justify-content: center;
  }
  section.parallax img.prod {
    max-width: 320px;
    right: 0;
    bottom: -250px;
  }
  #tecSlider,
  #researchSlider,
  #videosSlider {
    position: relative;
    width: 100%;
  }
  section.typer .typer-wrapper .vorax-typer {
    font-size: 3.2em;
  }
  section.harvest .container img {
    max-width: 340px;
  }

  .flex-between.result {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    justify-content: space-between;
    gap: 35px 25px;
    .result-item {
      position: relative;
      width: 100%;
      max-width: 100%;
      display: block;
      img {
        width: 100%;
      }
      .content {
        position: relative;
        width: 70%;
        padding: 14px 22px;
        background-color: #f7c222;
        margin: -20px auto 0 auto;
        left: -18px;
        z-index: 1;
      }
    }
  }

  .result-wrapper {
    gap: 35px 25px;
    .result-slide {
      max-width: 100%;
    }
  }

  .video-item {
    padding-bottom: 56.25%;
  }
}
</style>
